import styled from 'styled-components'

export const Container = styled.div`
  background: var(--accent);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    background: var(--accent-light);
  }
`
