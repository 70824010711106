import React, { FunctionComponent } from 'react'
import * as S from './styled'

type Props = {
  Icon: any
}

const Fab: FunctionComponent<Props> = ({ Icon }) => {
  return (
    <S.Container>
      <Icon />
    </S.Container>
  )
}

export default Fab
