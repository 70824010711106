import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import ContactForm from '../components/ContactForm'
import ContactInfo from '../components/ContactInfo'
import Header from '../components/Header/Header'
import '../css/index.css'
import { breakpoints, mediaQueries } from '../theme/mediaBreakpoints'

// TODO: Use react-grid-system when page is redesigned
const Grid = styled.div`
  background: var(--background);
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(40vw, 500px) 1fr;
  grid-template-areas: 'info form';

  ${breakpoints.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'form'
      'info';
  }

  ${breakpoints.mobile} {
    padding-top: 80px;
  }
`

const ContactPage = ({ location: { state = {} } }) => {
  const isMobile = useMediaQuery({
    query: mediaQueries.mobile,
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
        <meta
          name="description"
          content="Contact us to learn more about our services and how we can help you build your next digital product."
        />
        <html lang="en" />
      </Helmet>
      <main style={{ position: 'relative' }}>
        {isMobile && <Header />}
        <Grid>
          <span style={{ gridArea: 'info' }}>
            <ContactInfo />
          </span>
          <span style={{ gridArea: 'form' }}>
            <ContactForm defaultMessage={(state as { message?: string }).message} />
          </span>
        </Grid>
      </main>
    </>
  )
}

export default ContactPage
