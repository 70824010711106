import React, { FunctionComponent } from 'react'
import Facebook from '../../assets/svg/Facebook'
import Instagram from '../../assets/svg/Instagram'
import Linkedin from '../../assets/svg/Linkedin'
import LogoSmall from '../../assets/svg/LogoSmall'
import constants from '../../config/constants'
import { Spacer } from '../../theme/base'
import { Body3, Body4 } from '../../theme/typography'
import Fab from '../Fab/Fab'
import CustomLink from '../__general/CustomLink/CustomLink'
import * as S from './styled'

type Props = {}

const ContactInfo: FunctionComponent<Props> = () => {
  return (
    <S.Container>
      <S.Backdrop />
      <S.Content>
        <CustomLink to="/">
          <LogoSmall />
        </CustomLink>
        <Spacer size="large" />
        <Body4 white uppercase light>
          Email
        </Body4>
        <Spacer h16 />
        <Body3 white>Hello@redmind.se</Body3>
        <Spacer exact={50} />
        <Body4 white uppercase light>
          Phone
        </Body4>
        <Spacer h16 />
        <Body3 white>+46 08-23 08 10</Body3>
        <Spacer exact={50} />
        <Body4 white uppercase light>
          Address
        </Body4>
        <Spacer h16 />
        <Body3 white>{constants.addresses.stockholm}</Body3>
        <Spacer h16 />
        <Body3 white>{constants.addresses.linkoping}</Body3>
        <Spacer size="large" />
        <Body4 white uppercase light>
          Social
        </Body4>
        <Spacer h16 />
        <S.FabContainer>
          <a href={constants.socialLinks.facebook} rel="noopener noreferrer" target="_blank">
            <Fab Icon={Facebook} />
          </a>
          <Spacer w16 />
          <a href={constants.socialLinks.instagram} rel="noopener noreferrer" target="_blank">
            <Fab Icon={Instagram} />
          </a>
          <Spacer w16 />
          <a href={constants.socialLinks.linkedin} rel="noopener noreferrer" target="_blank">
            <Fab Icon={Linkedin} />
          </a>
        </S.FabContainer>
      </S.Content>
    </S.Container>
  )
}

export default ContactInfo
