import React, { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { Input, Spacer, TextArea } from '../../theme/base'
import { Body1, Headline4, Title3 } from '../../theme/typography'
import { submitNetlifyForm } from '../../util/helpers'
import Button from '../Button'
import * as S from './styled'

type Props = {
  defaultMessage?: string
}

const ContactForm: FunctionComponent<Props> = ({ defaultMessage }) => {
  const { handleSubmit, register, formState, reset } = useForm({
    defaultValues: { message: defaultMessage },
  })

  const onSubmit = async () => {
    try {
      const form = document.querySelector<HTMLFormElement>('#contact-form')
      await submitNetlifyForm(form)
      reset()
    } catch (error) {
      alert('Oj då! Ett fel uppstod, det gick inte att skicka iväg formuläret.')
    }
  }

  return (
    <S.Container>
      <Body1>
        We’re not a strategy consultancy, a design studio,
        <br />
        an app development company, but we do all of these things!
      </Body1>
      <Spacer h40 />
      <Title3>
        Do you need our expertise?
        <br />
        Let's talk!
      </Title3>
      <Spacer h40 />
      <Spacer h40 />
      <S.Form onSubmit={handleSubmit(onSubmit)} id="contact-form">
        <input type="hidden" name="form-name" value="contact" />
        <Input
          name="name"
          type="text"
          autoComplete="name"
          placeholder="Your name"
          disabled={formState.isSubmitted}
          ref={register({
            required: true,
          })}
        />
        <Input
          name="email"
          type="email"
          autoComplete="email"
          placeholder="Your email"
          disabled={formState.isSubmitted}
          ref={register({
            required: true,
          })}
        />
        <span className="full-width">
          <Spacer exact={40} />
        </span>
        <span className="full-width">
          <TextArea
            name="message"
            ref={register({
              required: true,
            })}
            placeholder="I have this amazing project in mind..."
          />
        </span>
        <span className="full-width">
          <Spacer exact={40} />
        </span>
        {formState.isSubmitted ? (
          <Headline4>Message sent!</Headline4>
        ) : (
          <Button title="Send message" style={{ maxWidth: '400px' }} />
        )}
      </S.Form>
    </S.Container>
  )
}

export default ContactForm
