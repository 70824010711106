import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'

export const Container = styled.div`
  padding: 90px 120px 90px 120px;

  ${breakpoints.mobile} {
    padding: 80px 40px;
  }
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;

  .full-width {
    grid-column: 1 / -1;
  }

  ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
`
