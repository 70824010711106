import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  padding: 80px 0;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
`

export const Content = styled.div`
  position: relative;
`

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
`

export const FabContainer = styled.div`
  display: flex;
`
